// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://api.millenniopizza-service.de/api/', // 'http://localhost:8000/api/', // 
  BASE_URL: 'https://api.millenniopizza-service.de', //'http://localhost:8000'
  DEFAULT_LANG: 'de',
  APP_VERSION: '5.3.2', // change app version to force clear cache
  GOOGLE_COUNTRIES: ["de"], // for google address
  GOOGLE_LOGIN_WEB_CLIENT_ID: '120287571060-m267iq10cqg8uhddss009k0c13v91lbh.apps.googleusercontent.com',
  FACEBOOK_LOGIN_APP_ID: '4279212058868424',
  // theme configuration
  DEFAULT_PRIMARY_COLOR: '#cc1fb5',
  DEFAULT_PRIMARY_TEXT_COLOR: '#cc8a1f',
  // LAST_MADE_CHANGE:'v5.2.7 : Bff board changes and Millennio board changes + ashan new changes + Praharsha modal size increased'
  // LAST_MADE_CHANGE:'v5.2.8 : Issues fixed and implemented new changes in millennio board'
  // LAST_MADE_CHANGE:'v5.2.9 : New reorder feature implemented and new changes in millennio board'
  // LAST_MADE_CHANGE:'v5.2.10 : new topping option limit feature implemented, 5.2.11 : Praharsha new cart icon design'
  LAST_MADE_CHANGE:'v5.2.12 : postal code request feature implemented for mobile multiple restaurants mode',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
