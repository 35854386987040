import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-input-select-modal',
  templateUrl: './input-select-modal.component.html',
  styleUrls: ['./input-select-modal.component.scss'],
})
export class InputSelectModalComponent implements OnInit {

  @Input() content: TemplateRef<any>;
  @Input() hideFooter: boolean = false;
  @Input() isSelectPayment = false;
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

 

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }


}
